.Speakers {
  display: flex;
  flex-direction: column;
}

.Speakers .PageHeaderWrapper {
  padding: 3rem 0;
  background-color: var(--primary-color);
}

.PageHeaderWrapper .PageHeading {
  color: #ffffff;
  font-family: "f-extrabold";
  text-align: center;
}

.ContentWrapper {
  padding: 2rem 4vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Card {
  width: 22%;
  padding: 1rem 0.5rem;
  border-radius: 0.2rem;
  border: 0.2rem solid #d1b2b2;
  cursor: pointer;
  margin: 1% 1.2%;
  bottom: 0px;
  transition: all 0.3s ease-in;
}

.Card .CardImage img {
  width: 100%;
  height: 390px;
}

.Card .CardName {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Card .Info {
  margin: 0.8rem 0;
}

.CardTopic {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .Card {
    width: 45%;
    margin: 2%;
  }
}

@media screen and (max-width: 570px) {
  .Card {
    width: 100%;
  }
}
