.Form {
  margin: 1rem;
}

.HFormGroup {
  display: flex;
  flex-direction: row;
}

.SubmitBtn {
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1rem 0.2rem;
  cursor: pointer;
}

.FormHead {
  font-size: 1.5rem;
  margin: 1rem 0;
}
