.PageHeader {
  margin: 2rem auto;
  font-family: "f-bold";
  font-size: 2rem;
  color: var(--primary-color);
}
.Backdrop {
  z-index: 99;
}

.PageContent {
  margin: 2rem;
  display: flex;
  flex-direction: row;
}

/* CART ITEMS */

.Cart {
  width: 60%;
}

.ProductContainer {
  width: 35%;
}

.Product {
  width: 100%;
}

.Product img {
  width: 100%;
  height: 180px;
}

.ProductDesc a {
  text-decoration: none;
  color: var(--primary-color);
}

.ProductName {
  font-family: "f-semibold";
}

.ProductType {
  text-transform: uppercase;
}

/* CHECKOUT */

.CheckoutContainer {
  display: flex;
  flex-direction: column;
  margin: 5rem auto;
  box-shadow: 8px 8px 16px #a1a1a1, -8px -8px 16px #ffffff;
  padding: 1rem;
  border-radius: 2%;
  height: 100%;
}

.CartBill .SubTotal {
  margin: 1rem 0;
  font-family: "f-bold";
}

.CartBill .CouponContainer {
  display: flex;
  flex-direction: row;
}

.CartBill .CouponContainer .CouponField {
  margin-right: 0.5rem;
}

.CartBill .CouponContainer .CouponField input {
  text-transform: uppercase;
}

.CartBill .Discount {
  margin: 0.5rem 0;
  font-family: "f-bold";
}

.CartBill .CartTotal {
  margin: 0.5rem 0;
  font-family: "f-extrabold";
}

.ClearCartButton {
  padding: 0.2rem 0;
  margin: 1rem 0;
}

.PaymentContainer {
  margin-top: 2px;
}

@media screen and (max-width: 725px) {
  .PageContent {
    margin: 2rem 1rem;
    display: flex;
    flex-direction: column;
  }

  /* CART ITEMS */

  .Cart {
    width: 100%;
  }

  .CheckoutContainer {
    flex-direction: column;
    padding: 2rem;
  }

  .CartBill .CouponContainer {
    flex-direction: column;
    margin: 1rem 0;
  }

  .CartBill .CouponContainer .CouponButton {
    margin: 1rem 0;
  }
}
