.SuccessPage {
  margin: 3rem auto;
}

.SuccessPage .SuccessIcon {
  font-size: 4rem;
  transform: rotate(-30deg);
  color: green;
}

.SuccessPage .SuccessMessage h1 {
  margin: 2rem;
}

.SuccessPage .SuccessMessage p {
  margin: 0.5rem;
  font-size: 1.5rem;
  width: 50%;
  margin: 0 auto;
}

.SetNewPassword {
  margin: 2rem auto;
  width: 30%;
}

.MaterialUIWrapper {
  margin: 2rem 0;
}

@media screen and (max-width: 768px) {
  .SetNewPassword {
    margin: 2rem auto;
    width: 90%;
  }
}
