.Image {
  animation-name: blurLoad;
  animation-duration: 1s;
}

@keyframes blurLoad {
  0% {
    filter: blur(5px);
  }
  20% {
    filter: blur(5px);
  }
  40% {
    filter: blur(5px);
  }
  60% {
    filter: blur(5px);
  }
  80% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0px);
  }
}
