.WorkshopsWrapper {
  display: flex;
  flex-direction: column;
}

.WorkshopsWrapper .PageHeaderWrapper {
  padding: 3rem 0;
  background-color: var(--primary-color);
}

.PageHeaderWrapper .PageHeading {
  color: #ffffff;
  font-family: "f-extrabold";
  text-align: center;
}

.ContentWrapper {
  margin: 3rem 4vw;
}

.ComingSoon {
  color: var(--primary-color);
  font-family: "f-bold";
  font-size: 1.5rem;
}
