@import "./assets/fonts/fonts.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: var(--line-height);
}

:root {
  font-size: 16px;
  --primary-color: #851a23;
  --primary-color-hover: #680e16cb;
  --line-height: 1.7rem;
  font-family: "f-regular";
}
