.swiper-container {
  width: 100% !important;
  /* overflow: initial !important; */
}

.InnerNavigation {
  width: 100%;
}

.SwiperCover {
  display: flex;
  flex-direction: row;
  position: relative;
}

.SwiperCover .arrow-left,
.SwiperCover .arrow-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.InnerNavigationCover .arrow-left {
  position: absolute;
  top: 50%;
  /* right: 100%; */
  z-index: 99;
  transform: translateY(-50%);
}

.InnerNavigationCover .arrow-right {
  position: absolute;
  top: 50%;
  left: 100%;
  z-index: 99;
  transform: translateY(-50%) translateX(-100%);
}

.SwiperCover .arrow-left svg,
.SwiperCover .arrow-right svg {
  background-color: rgba(233, 146, 146, 0.2);
  /* outline: 0.1rem solid rgb(133, 26, 35, 0.5); */
  border-radius: 0.1rem;
  opacity: 1;
  font-size: 3rem;
  padding: 0.2rem;
}
