.DropdownItem {
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  text-decoration: none;
  color: #000000;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  margin: 0 0.2rem;
  border-radius: 0.15rem;
}
.NavActive {
  background-color: var(--primary-color);
  color: #ffffff;
}
