.Header {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.2rem 4vw;
  background-color: #ffffff;
  border-bottom: 0.5rem solid var(--primary-color);
}

@media (max-width: 768px) {
  .Header {
    height: 88px;
  }
}
