.EditProfile {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.PageHeader {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.Form {
  width: 50%;
  min-width: 300px;
  margin: auto;
}

.Spacer {
  height: 2rem;
}

.InputLabel {
  text-align: left;
  font-family: "f-semibold";
  margin-bottom: 0.5rem;
}

.ProfileImageSection {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.TimelineImageHeader {
  margin: 1.5rem 0;
}

.ProfileImageSection img {
  width: 60%;
  min-width: 200px;
  margin-bottom: 1rem;
  max-height: 368px;
  box-shadow: 6px 6px 12px #b1b1b1, -6px -6px 12px #ffffff;
}

.ProfileTimelineImageSection {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.ProfileTimelineImageSection img {
  width: 100%;
  min-width: 200px;
  margin-bottom: 1rem;
  max-height: 368px;
  box-shadow: 6px 6px 12px #b1b1b1, -6px -6px 12px #ffffff;
}
