.ContactForm {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  width: 100%;
}

.ContactForm h1 {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.MaterialUIWrapper {
  margin: 1rem 0;
}

.BtnMessage {
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .ContactForm {
    margin: 0rem;
    padding: 0rem;
  }
}
