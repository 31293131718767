.TextFormat {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.TextFormat a {
  text-decoration: none;
  color: inherit;
  margin-left: 0.2rem;
}

.TextFormat .TextPara {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: auto;
}
