.InformalEventSection {
  display: flex;
  flex-direction: column;
}

.InformalEventSection .InformalEventType {
  font-family: "f-bold";
  font-size: 2rem;
  text-align: center;
  margin: 0 auto;
  text-transform: capitalize;
}

.InformalEventSection .InformalEventType::after {
  content: "";
  display: block;
  margin-top: 1rem;
  height: 0.1rem;
  width: 100%;
  border-bottom: 0.3rem dashed var(--primary-color);
}

.InformalEventSection .InformalEvents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 3rem 0;
}

.CardWrapper {
  margin: 1rem 0;
  width: 22%;
  margin: 1% 1.2%;
}

@media screen and (max-width: 768px) {
  .CardWrapper {
    width: 45%;
    margin: 2%;
  }
}

@media screen and (max-width: 500px) {
  .CardWrapper {
    width: 100%;
  }
}
