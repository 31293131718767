.Hamburger {
  width: 3rem;
  height: 2rem;
  position: relative;
  margin: auto;
  margin-right: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: none;
}

.Hamburger span {
  display: block;
  position: absolute;
  height: 0.4rem;
  width: 100%;
  background: var(--primary-color);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.Hamburger span:nth-child(1) {
  top: 0px;
}

.Hamburger span:nth-child(2) {
  top: 1rem;
}

.Hamburger span:nth-child(3) {
  top: 2rem;
}

.Hamburger.Open span:nth-child(1) {
  top: 1rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.Hamburger.Open span:nth-child(2) {
  opacity: 0;
  left: -3.5rem;
}

.Hamburger.Open span:nth-child(3) {
  top: 1rem;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media (max-width: 768px) {
  .Hamburger {
    display: block;
  }
}
