.AddConnexion {
  display: flex;
  flex-direction: column;
}

.PageHeader {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.Form {
  width: 60%;
  min-width: 300px;
  margin: auto;
}

.Spacer {
  height: 2rem;
}

.InputLabel {
  text-align: left;
  font-family: "f-semibold";
  margin-bottom: 0.5rem;
}

.ConnexionImageSection {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.TimelineImageHeader {
  margin: 1.5rem 0;
}

.ConnexionImageSection img {
  width: 60%;
  min-width: 200px;
  margin-bottom: 1rem;
  max-height: 368px;
  box-shadow: 6px 6px 12px #b1b1b1, -6px -6px 12px #ffffff;
}

.ConnexionTimelineImageSection {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.ConnexionTimelineImageSection img {
  width: 100%;
  min-width: 200px;
  margin-bottom: 1rem;
  max-height: 368px;
  box-shadow: 6px 6px 12px #b1b1b1, -6px -6px 12px #ffffff;
}

@media (max-width: 768px) {
  .Form {
    width: 100%;
  }

  .PageHeader {
    margin: 2rem;
  }
}
