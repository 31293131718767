.Dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.DropdownText {
  text-transform: uppercase;
  display: flex;
  font-family: "f-bold";
  flex-direction: row;
  justify-items: center;
  align-items: center;
  text-decoration: none;
  color: #000000;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  margin: 0 0.2rem;
  border-radius: 0.15rem;
}

.DropdownContent {
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: #ffffff;
}
